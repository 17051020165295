import React, { useState } from "react";
import Slider from "react-slick";
import "./RoomSlider.css";

const RoomSlider = ({ images }) => {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);

  const openFullscreen = (img) => {
    setCurrentImage(img);
    setIsFullscreen(true);
    document.documentElement.classList.add("overflow");
    document.body.classList.add("overflow");
  };

  const closeFullscreen = () => {
    setIsFullscreen(false);
    setCurrentImage(null);
    document.documentElement.classList.remove("overflow");
    document.body.classList.remove("overflow");
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  return (
    <div>
      <Slider {...settings}>
        {images.map((img, index) => (
          <div
            className="room-slide"
            key={index}
            onClick={() => openFullscreen(img)}
          >
            <img src={img} alt={`Room ${index + 1}`} />
          </div>
        ))}
      </Slider>

      {isFullscreen && (
        <div className="fullscreen-overlay" onClick={closeFullscreen}>
          <button className="close-btn" onClick={closeFullscreen}>
            +
          </button>
          <img src={currentImage} alt="Fullscreen" className="fullscreen-img" />
        </div>
      )}
    </div>
  );
};

// Custom arrows
const SampleNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", right: "10px" }}
      onClick={onClick}
    />
  );
};

const SamplePrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", left: "10px", zIndex: 1 }}
      onClick={onClick}
    />
  );
};

export default RoomSlider;
