import React from "react";

import "./Footer.css";
import Logo from "../Logo/Logo";
import { Link } from "react-router-dom";

import { Link as LinkScroll } from "react-scroll";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="content">
        <Logo variant={"dark"}></Logo>

        <div>
          <ul>
            <Link to={"https://maps.app.goo.gl/MoEN5YwyPnyYaNj58"}>
              <li>33 Bulevardul Griviței, Brașov </li>
            </Link>
            <Link to={"tel:40759162169"}>
              <li>Rezervări: 0759 162 169 </li>
            </Link>
            <Link to={"https://api.whatsapp.com/send?phone=40759162169"}>
              <li>Whatsapp: 0759 162 169 </li>
            </Link>
            <Link
              to={
                "https://www.instagram.com/randomhousebrasov?igsh=MW16OHd4azE4aWxvaA=="
              }
            >
              <li>Instagram: @random_house_brasov</li>
            </Link>
          </ul>
        </div>
        <hr />

        <div>
          <ul>
            <LinkScroll to="camera1" smooth={true} duration={500} offset={-100}>
              <li>Cameră Dublă </li>
            </LinkScroll>
            <LinkScroll to="camera2" smooth={true} duration={500} offset={-100}>
              <li>Cameră Delux </li>
            </LinkScroll>
            <LinkScroll to="camera3" smooth={true} duration={500} offset={-100}>
              <li>Apartament (2 dormitoare) </li>
            </LinkScroll>
            <LinkScroll to="camera4" smooth={true} duration={500} offset={-100}>
              <li>Cameră cvadruplă</li>
            </LinkScroll>
            <LinkScroll to="camera5" smooth={true} duration={500} offset={-100}>
              <li>Cameră Deluxe dublă </li>
            </LinkScroll>
          </ul>
        </div>
        <hr />

        <Link to={"https://anpc.ro/ce-este-sal/"}>
          <img
            className="anpc"
            src="https://res.cloudinary.com/dsqwnuyiw/image/upload/v1725189947/anpc1_wmrzod.png"
            alt="anpc"
          />
        </Link>
        <Link
          to={
            "https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=RO"
          }
        >
          <img
            className="anpc"
            src="https://res.cloudinary.com/dsqwnuyiw/image/upload/v1725189947/anpc2_vyfodi.png"
            alt="anpc"
          />
        </Link>
      </div>
      <p className="p-1">© 2024 Copyright Random House Brașov</p>
    </footer>
  );
};

export default Footer;
