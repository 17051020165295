import React, { useState, useEffect } from "react";
import "./Navbar.css";
import Logo from "../Logo/Logo";
import { Link } from "react-router-dom";
import { Link as LinkScroll } from "react-scroll";
import Btn from "../Btn/Btn";

const Navbar = () => {
  const [scrolled, setScrolled] = useState(false);
  const [menuToggled, setMenuToggled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 300) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (menuToggled) {
      document.body.classList.add("toggled");
      document.documentElement.classList.add("toggled");
      document.documentElement.classList.add("overflow");
    } else {
      document.body.classList.remove("toggled");
      document.documentElement.classList.remove("toggled");
      document.documentElement.classList.remove("overflow");
    }
  }, [menuToggled]);

  const toggleMenu = () => {
    setMenuToggled(!menuToggled);
  };

  let navbarClasses = "navbar";
  if (scrolled) {
    navbarClasses += " scrolled";
  }

  return (
    <nav className={navbarClasses}>
      <Logo variant={"light"} />

      <LinkScroll to="contact" smooth={true} duration={500} offset={-100}>
        <Btn variant={"accent-2"}>Reservă</Btn>
      </LinkScroll>

      <div className="menu-btn" onClick={toggleMenu}>
        <span className="span span1"></span>
        <span className="span span2"></span>
        <span className="span span3"></span>
      </div>
      <div className="links">
        <ul>
          <li className="header">Date de contact</li>
          <Link to={"https://maps.app.goo.gl/MoEN5YwyPnyYaNj58"}>
            <li>33 Bulevardul Griviței, Brașov </li>
          </Link>
          <Link to={"tel:40759162169"}>
            <li>Rezervări: 0759 162 169 </li>
          </Link>
          <Link to={"https://api.whatsapp.com/send?phone=40759162169"}>
            <li>Whatsapp: 0759 162 169 </li>
          </Link>
          <Link
            to={
              "https://www.instagram.com/randomhousebrasov?igsh=MW16OHd4azE4aWxvaA=="
            }
          >
            <li>Instagram: @random_house_brasov</li>
          </Link>
        </ul>

        <ul>
          <li className="header">Camere</li>
          <LinkScroll
            onClick={toggleMenu}
            to="camera1"
            smooth={true}
            duration={500}
            offset={-100}
          >
            <li>Cameră Dublă - 2 adulți</li>
          </LinkScroll>
          <LinkScroll
            onClick={toggleMenu}
            to="camera2"
            smooth={true}
            duration={500}
            offset={-100}
          >
            <li>Cameră Deluxe (2 adulți + 1 copil)</li>
          </LinkScroll>
          <LinkScroll
            onClick={toggleMenu}
            to="camera3"
            smooth={true}
            duration={500}
            offset={-100}
          >
            <li>Apartament cu 4 dormitoare</li>
          </LinkScroll>
          <LinkScroll
            onClick={toggleMenu}
            to="camera4"
            smooth={true}
            duration={500}
            offset={-100}
          >
            <li>Cameră cvadruplă</li>
          </LinkScroll>
          <LinkScroll
            onClick={toggleMenu}
            to="camera5"
            smooth={true}
            duration={500}
            offset={-100}
          >
            <li>Cameră Deluxe dublă sau twin</li>
          </LinkScroll>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
