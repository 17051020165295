import React from "react";

import "./Logo.css";

const Logo = ({ variant }) => {
  return (
    <div className={`logo ${variant}`}>
      <img
        src="https://res.cloudinary.com/dsqwnuyiw/image/upload/v1725174853/randomhouse/logo.png"
        alt="logo"
      />
    </div>
  );
};

export default Logo;
