import React from "react";

import HomeSlider from "../../Components/HomeSlider/HomeSlider";
import Btn from "../../Components/Btn/Btn";

import "./Home.css";
import RoomSlider from "../../Components/RoomSlider/RoomSlider";
import { Link } from "react-router-dom";

const Home = () => {
  const room1Images = [
    "https://res.cloudinary.com/dsqwnuyiw/image/upload/v1725274177/randomhouse/dubla-1/DSC_0117-min_gyqy5g.webp",
    "https://res.cloudinary.com/dsqwnuyiw/image/upload/v1725274174/randomhouse/dubla-1/DSC_0110-min_yszb7o.webp",
    "https://res.cloudinary.com/dsqwnuyiw/image/upload/v1725274182/randomhouse/dubla-1/DSC_0125-min_mkafu3.webp",
    "https://res.cloudinary.com/dsqwnuyiw/image/upload/v1725274180/randomhouse/dubla-1/DSC_0120-min_sq9j6j.webp",
    "https://res.cloudinary.com/dsqwnuyiw/image/upload/v1725274181/randomhouse/dubla-1/DSC_0123-min_ryi39a.webp",
    "https://res.cloudinary.com/dsqwnuyiw/image/upload/v1725271169/randomhouse/dubla-2/DSC_0199-min_fxj5dc.webp",
    "https://res.cloudinary.com/dsqwnuyiw/image/upload/v1725271170/randomhouse/dubla-2/DSC_0202-min_srriat.webp",
    "https://res.cloudinary.com/dsqwnuyiw/image/upload/v1725271170/randomhouse/dubla-2/DSC_0201-min_lsjnzm.webp",
    "https://res.cloudinary.com/dsqwnuyiw/image/upload/v1725271171/randomhouse/dubla-2/DSC_0203-min_bmwcsw.webp",
    "https://res.cloudinary.com/dsqwnuyiw/image/upload/v1725271173/randomhouse/dubla-2/DSC_0206-min_hdhwgr.webp",
    "https://res.cloudinary.com/dsqwnuyiw/image/upload/v1725271172/randomhouse/dubla-2/DSC_0219-min_aryygu.webp",
    "https://res.cloudinary.com/dsqwnuyiw/image/upload/v1725271172/randomhouse/dubla-2/DSC_0227-min_nhuato.webp",
    "https://res.cloudinary.com/dsqwnuyiw/image/upload/v1725271173/randomhouse/dubla-2/DSC_0228-min_pqbrcq.webp",
    "https://res.cloudinary.com/dsqwnuyiw/image/upload/v1725271174/randomhouse/dubla-2/DSC_0229-min_dtuqai.webp",
  ];

  const room2Images = [
    "https://res.cloudinary.com/dsqwnuyiw/image/upload/v1725274718/randomhouse/delux-2/DSC_0127_1_-min_leic6b.webp",
    "https://res.cloudinary.com/dsqwnuyiw/image/upload/v1725274720/randomhouse/delux-2/DSC_0130-min_kw8qh3.webp",
    "https://res.cloudinary.com/dsqwnuyiw/image/upload/v1725274719/randomhouse/delux-2/DSC_0128-min_cmddfw.webp",
    "https://cf.bstatic.com/xdata/images/hotel/max1024x768/537268002.jpg?k=0192e4af039f2d1a73dd9ea18b4b0af59d4c0f2656e8c62918b901a6f4b55d50&o=",
    "https://cf.bstatic.com/xdata/images/hotel/max1024x768/544094386.jpg?k=13a445d41de233590d11c5fb91d392ff8138912218546cc99cb6ed5c097a053f&o=",
    "https://cf.bstatic.com/xdata/images/hotel/max1024x768/544094670.jpg?k=bcaa3195acfaa6c70c29dba3e5df8740efd1b58ea7f5dc915c831c234bb72ff7&o=",
    "https://cf.bstatic.com/xdata/images/hotel/max1024x768/544094771.jpg?k=6d5daa5d385c46decfefd122240d69a0284368d6cb4b2d9a313176d3e7b349d5&o=",
    "https://cf.bstatic.com/xdata/images/hotel/max1024x768/544109700.jpg?k=bf12453c8dbd65fe1b125379d411ed23680fb089def44b19a8716d84d45cf95d&o=",
    "https://cf.bstatic.com/xdata/images/hotel/max1024x768/544109699.jpg?k=85bce6ce6e7f47752c2b3ee70f6213af76c05be2b1a7398b619bc643005c720e&o=",
  ];

  const room3Images = [
    "https://cf.bstatic.com/xdata/images/hotel/max1024x768/544693127.jpg?k=247d692a8ffead880e66fd5141acbb65466f7d8b2c820c02f8ecabb7486d331a&o=",
    "https://cf.bstatic.com/xdata/images/hotel/max1024x768/544089813.jpg?k=07bda1781dab737b8675e4173e449d03ec458d4e15a80ac90628752097b39419&o=",
    "https://cf.bstatic.com/xdata/images/hotel/max1024x768/544693146.jpg?k=f07239493bddfdd27fc0996e9b5abd675b3a85d63671487815d839005779d174&o=",
    "https://cf.bstatic.com/xdata/images/hotel/max1024x768/544089802.jpg?k=4769334453ec9ebda4d89c8e9e45742c1c777fce25623f81c2247d032e0c0267&o=",
    "https://cf.bstatic.com/xdata/images/hotel/max1024x768/544692832.jpg?k=6f61e6226a833e5a174e9340f9229a963b67a3e883b6831edaefe6b390314172&o=",
    "https://cf.bstatic.com/xdata/images/hotel/max1024x768/544692845.jpg?k=7eea822c959ce73238a64e8428df0b580961e1e9415c6f04152760dd79fa0308&o=",
  ];

  const room4Images = [
    "https://res.cloudinary.com/dsqwnuyiw/image/upload/v1725270206/randomhouse/4beds/DSC_0319-min_hjtr7j.webp",
    "https://res.cloudinary.com/dsqwnuyiw/image/upload/v1725270205/randomhouse/4beds/DSC_0314-min_qslelt.webp",
    "https://res.cloudinary.com/dsqwnuyiw/image/upload/v1725270206/randomhouse/4beds/DSC_0318-min_enztm3.webp",
    "https://res.cloudinary.com/dsqwnuyiw/image/upload/v1725270206/randomhouse/4beds/DSC_0317-min_jc6zbl.webp",
    "https://res.cloudinary.com/dsqwnuyiw/image/upload/v1725270206/randomhouse/4beds/DSC_0315-min_i9rade.webp",
    "https://res.cloudinary.com/dsqwnuyiw/image/upload/v1725270205/randomhouse/4beds/DSC_0314-min_qslelt.webp",
    "https://res.cloudinary.com/dsqwnuyiw/image/upload/v1725270206/randomhouse/4beds/DSC_0320-min_fqxtbm.webp",
  ];

  const room5Images = [
    "https://res.cloudinary.com/dsqwnuyiw/image/upload/v1725269396/randomhouse/tween/DSC_0001-min_fvb67b.webp",
    "https://res.cloudinary.com/dsqwnuyiw/image/upload/v1725269396/randomhouse/tween/DSC_0006-min_c4yilw.webp",
    "https://res.cloudinary.com/dsqwnuyiw/image/upload/v1725269396/randomhouse/tween/DSC_0002-min_emn2ly.webp",
    "https://res.cloudinary.com/dsqwnuyiw/image/upload/v1725269396/randomhouse/tween/DSC_0005-min_lfv0od.webp",
    "https://res.cloudinary.com/dsqwnuyiw/image/upload/v1725269396/randomhouse/tween/DSC_0004-min_qiim51.webp",
    "https://res.cloudinary.com/dsqwnuyiw/image/upload/v1725269397/randomhouse/tween/DSC_0007-min_ejhv7f.webp",
    "https://res.cloudinary.com/dsqwnuyiw/image/upload/v1725269397/randomhouse/tween/DSC_0009-min_eocsfj.webp",
    "https://res.cloudinary.com/dsqwnuyiw/image/upload/v1725269397/randomhouse/tween/DSC_0008-min_vz4b9u.webp",
    "https://res.cloudinary.com/dsqwnuyiw/image/upload/v1725269397/randomhouse/tween/DSC_0011-min_e7pzfg.webp",
    "https://res.cloudinary.com/dsqwnuyiw/image/upload/v1725269397/randomhouse/tween/DSC_0013-min_ykcwnr.webp",
    "https://res.cloudinary.com/dsqwnuyiw/image/upload/v1725269398/randomhouse/tween/DSC_0017-min_jcvqvk.webp",
    "https://res.cloudinary.com/dsqwnuyiw/image/upload/v1725269398/randomhouse/tween/DSC_0018-min_vkybf3.webp",
    "https://res.cloudinary.com/dsqwnuyiw/image/upload/v1725269398/randomhouse/tween/DSC_0015-min_ateq4w.webp",
    "https://res.cloudinary.com/dsqwnuyiw/image/upload/v1725269398/randomhouse/tween/DSC_0022-min_o5rrj6.webp",
  ];

  return (
    <main>
      <section className="hero">
        <div className="content">
          <h1>
            Random House <br /> Brasov
          </h1>
          <p className="p-1">
            Descoperă rafinamentul și confortul absolut la Random House Brașov,
            unde eleganța modernă întâlnește tradiția transilvăneană.
          </p>
          <Btn variant={"light"}>Află mai multe</Btn>
        </div>
        <HomeSlider></HomeSlider>
      </section>
      <section className="info">
        <div className="content">
          <h2>Eleganță și Rafinamet</h2>
          <p className="p-1">
            Random House oferă o oază de relaxare cu un lounge comun, terasă și
            parcare privată gratuită.
          </p>
          <div className="imgs imgs-1">
            <img
              src="https://res.cloudinary.com/dsqwnuyiw/image/upload/v1725209556/randomhouse/Group_2_2_ojhlfi.webp"
              alt="img 1"
            />
          </div>
          <h2>Răsfăț și Relaxare</h2>
          <p className="p-1">
            Camerele sunt rafinate și complet echipate, incluzând articole de
            toaletă premium. De asemenea, oaspeții au acces la o bucătărie
            comună complet utilată, dotată cu frigider, mașină de spălat vase și
            cafetieră, pentru un confort maxim pe durata șederii.
          </p>
          <div className="imgs imgs-1">
            <img
              src="https://res.cloudinary.com/dsqwnuyiw/image/upload/v1725276743/Group_4_1_sjwvmw.png"
              alt="img 2"
            />
          </div>
          <h2>Aproape de Tot ce contează</h2>
          <p className="p-1">
            La 3,6 km de Piața Sfatului și la 4,5 km de Strada Sforii, Random
            House este locul perfect pentru a explora Brașovul. Aeroportul
            Internațional Brașov-Ghimbav este la doar 10 km distanță, asigurând
            o accesibilitate excelentă.
          </p>
          <div className="imgs imgs-1">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2788.5150518957507!2d25.59254197694458!3d45.660559671077884!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40b35b27a5236f1b%3A0x4b86d21876bb43f8!2sRandom%20House%20Brasov!5e0!3m2!1sen!2sro!4v1725181901595!5m2!1sen!2sro"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </section>
      <hr />{" "}
      <section className="rooms">
        <div className="content">
          <div className="room" id="camera1">
            <h2>2 Camere Duble - 2 adulți</h2>
            <RoomSlider images={room1Images} />
            <div className="details">
              <div className="detail">
                <div>21 mp.</div> <div>Suprafață</div>
              </div>
              <div className="detail">
                <div>2</div> <div>Nr. oaspeți</div>
              </div>
              <div className="detail">
                <div>2</div> <div>Paturi Single</div> / 1 matrimonial
              </div>
            </div>
            <Link to={"tel:40759162169"}>
              <Btn variant={"dark"}>Află mai multe</Btn>
            </Link>
          </div>
          <hr />
          <div className="room" id="camera2">
            <h2>
              2 Camere Deluxe <br />
            </h2>
            <RoomSlider images={room2Images} />
            <div className="details">
              <div className="detail">
                <div>25 mp.</div> <div>Suprafață</div>
              </div>
              <div className="detail">
                <div>3</div> <div>Nr. oaspeți</div>
              </div>
              <div className="detail">
                <div>3</div>{" "}
                <div style={{ fontFamily: "poppins" }}>Paturi Single</div> / 1
                matrimonial <br /> + 1 single
              </div>
            </div>
            <Link to={"tel:40759162169"}>
              <Btn variant={"dark"}>Află mai multe</Btn>
            </Link>
          </div>
          <hr />
          <div className="room" id="camera3">
            <h2>
              Apartament cu <br /> 2 dormitoare
            </h2>
            <RoomSlider images={room3Images} />
            <div className="details">
              <div className="detail">
                <div>25 mp.</div> <div>Suprafață</div>
              </div>
              <div className="detail">
                <div>4</div> <div>Nr. oaspeți</div>
              </div>
              <div className="detail">
                <div>4</div> <div>Paturi Single</div>
              </div>
            </div>

            <Link to={"tel:40759162169"}>
              <Btn variant={"dark"}>Află mai multe</Btn>
            </Link>
          </div>
          <hr />
          <div className="room" id="camera4">
            <h2>Cameră cvadruplă</h2>
            <RoomSlider images={room4Images} />
            <div className="details">
              <div className="detail">
                <div>38 mp.</div> <div>Suprafață</div>
              </div>
              <div className="detail">
                <div>4</div> <div>Nr. oaspeți</div>
              </div>
              <div className="detail">
                <div>4</div> <div>Paturi Single</div>
              </div>
            </div>
            <Link to={"tel:40759162169"}>
              <Btn variant={"dark"}>Află mai multe</Btn>
            </Link>
          </div>
          <hr />
          <div className="room" id="camera5">
            <h2>Cameră Deluxe dublă sau tween</h2>
            <RoomSlider images={room5Images} />
            <div className="details">
              <div className="detail">
                <div>21 mp.</div> <div>Suprafață</div>
              </div>
              <div className="detail">
                <div>2</div> <div>Nr. oaspeți</div>
              </div>
              <div className="detail">
                <div>2</div> <div>Paturi Single</div>
              </div>
            </div>
            <Link to={"tel:40759162169"}>
              <Btn variant={"dark"}>Află mai multe</Btn>
            </Link>
          </div>
        </div>
      </section>
      <section className="contact" id="contact">
        <div className="content">
          <h2>Dorești să rezervi ?</h2>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2788.5150518957507!2d25.59254197694458!3d45.660559671077884!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40b35b27a5236f1b%3A0x4b86d21876bb43f8!2sRandom%20House%20Brasov!5e0!3m2!1sen!2sro!4v1725181901595!5m2!1sen!2sro"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
          <div className="option">
            <Link to={"https://maps.app.goo.gl/MoEN5YwyPnyYaNj58"}>
              <Btn variant={"accent"}>
                <img
                  src="https://res.cloudinary.com/dsqwnuyiw/image/upload/v1725188456/location_knhngf.svg"
                  alt="location"
                />
                33 Bulevardul Griviței, Brașov
              </Btn>
            </Link>
          </div>
          <div className="option">
            <Link to={"tel:40759162169"}>
              <Btn variant={"accent"}>
                <img
                  src="https://res.cloudinary.com/dsqwnuyiw/image/upload/v1725188456/ph_l9zpop.svg"
                  alt="location"
                />
                Contactează-ne telefonic
              </Btn>
            </Link>
          </div>
          <div className="option">
            <Link to={"https://api.whatsapp.com/send?phone=40759162169"}>
              <Btn variant={"accent"}>
                <img
                  src="https://res.cloudinary.com/dsqwnuyiw/image/upload/v1725188456/wp_lbndmv.svg"
                  alt="location"
                />
                Contactează-ne pe Whatsapp
              </Btn>
            </Link>
          </div>
          <div className="option">
            <Link
              to={
                "https://www.instagram.com/randomhousebrasov?igsh=MW16OHd4azE4aWxvaA=="
              }
            >
              <Btn variant={"accent"}>
                <img
                  src="https://res.cloudinary.com/dsqwnuyiw/image/upload/v1725188456/ig_kguxgz.svg"
                  alt="location"
                />
                Pagină de Instagram
              </Btn>
            </Link>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Home;
