import React from "react";

import "./HomeSlider.css";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function HomeSlider() {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div className="home-slider">
      <Slider {...settings}>
        <div className="home-img">
          <img
            src="https://cf.bstatic.com/xdata/images/hotel/max1024x768/537268002.jpg?k=0192e4af039f2d1a73dd9ea18b4b0af59d4c0f2656e8c62918b901a6f4b55d50&o="
            alt="home img"
          />
        </div>
        <div className="home-img">
          <img
            src="https://res.cloudinary.com/dsqwnuyiw/image/upload/v1725178389/randomhouse/DSC_0292_2_hkm9et.webp"
            alt="home img"
          />
        </div>
        <div className="home-img">
          <img
            src="https://res.cloudinary.com/dsqwnuyiw/image/upload/v1725188790/DSC_0306_1_mvfrzd.webp"
            alt="home img"
          />
        </div>
      </Slider>
    </div>
  );
}
